html { 
  font-size: 18px; 
}

div.c {
  text-align: center; /* For Edge */
  -moz-text-align-last: center; /* For Firefox prior 58.0 */
  text-align-last: center;
  margin: 0 auto;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  /*width: 50%;*/
}

.c05in100 {
  text-align: center; /* For Edge */
  -moz-text-align-last: center; /* For Firefox prior 58.0 */
  text-align-last: center;
  margin: 0 auto;
  /*background: red;*/
  height: 5%;
  aspect-ratio: 100 / 5;
}

.c95in100 {
  text-align: center; /* For Edge */
  -moz-text-align-last: center; /* For Firefox prior 58.0 */
  text-align-last: center;
  margin: 0 auto;
  /*background: teal;*/
  height: 95%;
  aspect-ratio: 100 / 95;
}


.collapsible .content {
  padding: 5px;
  height: 400px;
  overflow-y: scroll;
}

.collapsible .header-expand {
  background-color: #4bd7ec9a;
  padding: 0px;
  cursor: pointer;
  border: 0px;
  border-radius: 1.5rem;
  box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
}
.collapsible .header-collapse {
  background-color: #dddddd;
  padding: 0px;
  cursor: pointer;
  border-radius: 1.5rem;
  box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
}

.paotung {
  cursor: pointer;
  height: 100px;
  background: no-repeat;
}