/* Global */
body {
  background: #fafafa;
  margin: 0;
  font-family: 'Lato', sans-serif;
  color: rgba(0,0,0,.8);
}
h1, h2 {
  margin: 0;
}
a {
  /* color: #00ab6b; */
  text-decoration: none;
}
/* helpers */
.clearfix:after {
  content:"";
  display:block;
  clear:both;
}
/* Fonts */
.droid {
  font-family: 'Droid Serif', serif;
}
/* Components */
.container {
  max-width: 740px;
  margin: 0 auto;
  padding: 0 15px;
}
.header {
  padding: 20px 20px 0 20px;
  margin-bottom: 30px;
}
.footer {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 0 20px; 
}
.copyright {
  float: right;
}
.site-title {
  margin-bottom: 20px;
}
.site-title a {
  color: rgba(0,0,0,.8);
}
.site-title__tag {
  float: right;
  color: #aaa;
  font-size: 14px;
  margin-top: 10px;
}
.card {
  margin-bottom: 40px;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,0,0,.04);
  border: 1px solid rgba(0,0,0,.09);
  border-radius: 3px;
}
.card-padding {
  padding: 20px;
}
.no-link {
  cursor: default;
}
/* Blog page */
.blog__title {
  margin-bottom: 20px;
  font-size: 40px;
}
.blog__title--small {
  font-size: 29px;
}
.blog__title a {
  color: rgba(0,0,0,.8);
}
.blog__teaser,
.blog__content {
  margin-bottom: 30px;
  --x-height-multiplier: 0.35;
  --baseline-multiplier: 0.179;
  letter-spacing: .01rem;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -.003em;
}
.blog__author {
  margin-bottom: 20px;
}
.blog__author-title {
  position: relative;
  top: 7px;
  float: left;
  color: #666;
}
.blog__author-image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 36px;
  height: 36px;
  border-radius: 100px;
  margin-right: 10px;
}
.blog__tags {
  margin-bottom: 20px;
}
.tag-pill {
  border: none;
  color: rgba(0,0,0,.6);
  background: rgba(0,0,0,.05);
  margin-right: 10px;
  padding: 8px 10px 10px 10px;
  font-size: 13px;
}
.blog-post-hero {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
}
.blog-post-hero--short {
  height: 250px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  margin-bottom: 0;
  display: block;
}
/* Author page */
.author-page-title {
  margin-bottom: 15px;
}
.source-code-message {
  text-align: center;
  font-size: 15px;
  padding: 10px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0,0,0,.05);
  background-color: #fff;
}
.source-code-message .underline {
  text-decoration: underline;
}