
input:focus {
  outline: none;
  box-shadow: none!important;
  /*Sometime the blue border you see is from the box-shadow and removing box-shadow will remove the border*/
}

#input-box::placeholder {
  /*color: blue;*/
  opacity: 1;
}

@media (min-width: 1150px){
  .input-icon-addon {
    position: relative;
    border-radius: 1.5rem;
    border: 1px solid transparent;
    border-color: rgba(223,225,229,0);
    box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
    margin: 0 auto;
    background: #fff;
    min-width: 300px;
    width: 30%;
    height: 2.5rem;
  }
  .input-icon-addon #input-box {
    border: 0px;                     
    padding-top: 0.35rem;
    margin-left : 5%;
    margin-right : 5%;
    text-align-last: center;
    font-size: 1.25rem;
    width: 100%;
  }

  .input-icon-addon #input-icon {
    position: absolute;
    padding-left : 90%;
    padding-top: 0.5rem;
    text-align-last: right;
    pointer-events: none;
    font-size: 1.25rem;
    color: gray;
  }
}

@media (min-width: 800px) and (max-width: 1149px){
  .input-icon-addon {
    position: relative;
    border-radius: 1.5rem;
    border: 1px solid transparent;
    border-color: rgba(223,225,229,0);
    box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
    margin: 0 auto;
    background: #fff;
    min-width: 300px;
    width: 30%;
    height: 2rem;
  }
  .input-icon-addon #input-box {
    border: 0px;                     
    padding-top: 0.35rem;
    margin-left : 5%;
    margin-right : 5%;
    text-align-last: center;
    font-size: 1rem;
    width: 100%;
  }

  .input-icon-addon #input-icon {
    position: absolute;
    padding-left : 90%;
    padding-top: 0.4rem;
    text-align-last: right;
    pointer-events: none;
    font-size: 1rem;
    color: gray;
  }
}

@media (max-width: 799px){
  .input-icon-addon {
    position: relative;
    border-radius: 1.5rem;
    border: 1px solid transparent;
    border-color: rgba(223,225,229,0);
    box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
    margin: 0 auto;
    background: #fff;
    min-width: 280px;
    width: 80%;
    height: 2.5rem;
  }
  .input-icon-addon #input-box {
    border: 0px;
    padding-left : 0%;                      
    padding-top: 0.5rem;
    margin-left : 5%;
    margin-right : 5%;
    text-align-last: center;
    font-size: 1rem;
    width: 100%;
  }

  .input-icon-addon #input-icon {
    position: absolute;
    padding-left : 85%;
    padding-top: 0.6rem;
    text-align-last: right;
    /*pointer-events: none;*/
    font-size: 1rem;
    color: gray;
    width: 10%;
  }
}


.blinktext {
	-webkit-animation: blink-text 800ms linear infinite;
	-moz-animation: blink-text 800ms linear infinite;
	-ms-animation: blink-text 800ms linear infinite;
	-o-animation: blink-text 800ms linear infinite;
	 animation: blink-text 1000ms linear infinite;
}
@-webkit-keyframes blink-text {
	0% { color: blue;opacity: 1; }
	20% { color: blue;opacity: .8; }
	30% { color: blue;opacity: .6; }
	40% { color: blue;opacity: .4; }
	50% { color: blue;opacity: .2; }
	60% { color: blue;opacity: 0; }
	70% { color: blue;opacity: .2; }
	80% { color: blue;opacity: .4; }
	90% { color: blue;opacity: .6; }
	98% { color: blue;opacity: .8; }
	100% { color: blue;opacity: 1; }
}
@-moz-keyframes blink-text {
	0% { color: blue;opacity: 1; }
	20% { color: blue;opacity: .8; }
	30% { color: blue;opacity: .6; }
	40% { color: blue;opacity: .4; }
	50% { color: blue;opacity: .2; }
	60% { color: blue;opacity: 0; }
	70% { color: blue;opacity: .2; }
	80% { color: blue;opacity: .4; }
	90% { color: blue;opacity: .6; }
	98% { color: blue;opacity: .8; }
	100% { color: blue;opacity: 1; }
}
@-ms-keyframes blink-text {
	0% { color: blue;opacity: 1; }
	20% { color: blue;opacity: .8; }
	30% { color: blue;opacity: .6; }
	40% { color: blue;opacity: .4; }
	50% { color: blue;opacity: .2; }
	60% { color: blue;opacity: 0; }
	70% { color: blue;opacity: .2; }
	80% { color: blue;opacity: .4; }
	90% { color: blue;opacity: .6; }
	98% { color: blue;opacity: .8; }
	100% { color: blue;opacity: 1; }
}
@-o-keyframes blink-text {
	0% { color: blue;opacity: 1; }
	20% { color: blue;opacity: .8; }
	30% { color: blue;opacity: .6; }
	40% { color: blue;opacity: .4; }
	50% { color: blue;opacity: .2; }
	60% { color: blue;opacity: 0; }
	70% { color: blue;opacity: .2; }
	80% { color: blue;opacity: .4; }
	90% { color: blue;opacity: .6; }
	98% { color: blue;opacity: .8; }
	100% { color: blue;opacity: 1; }
}
@keyframes blink-text {
	0% { color: blue;opacity: 1; }
	20% { color: blue;opacity: .8; }
	30% { color: blue;opacity: .6; }
	40% { color: blue;opacity: .4; }
	50% { color: blue;opacity: .2; }
	60% { color: blue;opacity: 0; }
	70% { color: blue;opacity: .2; }
	80% { color: blue;opacity: .4; }
	90% { color: blue;opacity: .6; }
	98% { color: blue;opacity: .8; }
	100% { color: blue;opacity: 1; }
}