.lazada-landing-mobile {
  cursor: pointer;
  max-height: 100px;
  width: 100%;
  background: no-repeat;
}
.lazada-landing-tablet {
  cursor: pointer;
  max-height: 100px;
  width: 100%;
  background: no-repeat;
}
.lazada-landing-desktop {
  cursor: pointer;
  max-height: 240px;
  width: 100%;
  background: no-repeat;
}

.lazada-product1x1 {
  cursor: pointer;
  max-height: 300px;
  background: no-repeat;
}
